import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
//import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    //private tokenService: TokenService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (localStorage.getItem('token') != null) {
      
    //   if(moduleId && permissionId) {
    //     if (this.tokenService.hasPermission(moduleId, permissionId)) {
    //       return true;
    //     } 
    //     this.router.navigate(['/authentication/login']);
    //     return false;
    //   } else {
        return true;
    //   }

    } else {
      this.router.navigate(['/auth/login']);
      return false;
    }
  }


}